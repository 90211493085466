import React, {useEffect } from "react";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import Masvistos from "../components/masvistos";
import Markdown from "react-markdown";
import Trucos from "../components/trucos";
import Newsletter from "../components/newsletter";

const Trucoscocina = () => {
    const seo = {
      metaTitle: "Los trucos más fáciles para cocinar sano",
      metaDescription: "Conoce los mejores trucos para preparar en tu cocina facilmente.: técnicas de cocina, los mejores electrodomésticos",
      shareImage: ""
    };

  return (
    <Layout seo={seo}>
        
        <div className="grid grid-cols-1 gap-4 md:gap-6 md:grid-cols-3 sm:px-6 lg:max-w-5xl lg:px-0 mx-auto">
            <div className="py-0  md:col-span-2 ...">
                <h1 className="max-w-3xl m-auto poppins  pt-2 md:text-s38 text-s33 leading-8 md:leading-11 pb-6 text-black text-center">Trucos saludables de cocina</h1>
                <Trucos />
            </div>
            <div className="px-4 md:pl-4 md:ml-2 md:border-l md:pr-10 md:mt-16">
                <div className="poppins font-medium text-25 sm:pt-2 leading-7 pb-2">Del huerto a tu mesa</div>
                <div className="archebook text-20 text-gristexto">Aunque en la cocina se transforman los alimentos para crear platos deliciosos con técnicas culinarias,  saber comprar es un paso previo crucial. En está sección encontrarás información muy útil que te ayudará a cocinar en menos tiempo y con éxito garantizado.</div>
                <div className="text-marron poppins w500 bg-amarillo text-xl text-center py-3 mt-8">LO MÁS VISTO</div>
                <div className="pl-0 pr-10 md:pr-2">
                    <Masvistos/>
                </div>
            </div>
        </div>
        <Newsletter />
    </Layout>
  );
};

export default Trucoscocina;